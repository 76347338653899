<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import FormPage from '../form/form';
import audit from '../audit/audit';
// 审核
export default {
  extends: TablePage,
  components: {
    FormPage,
    audit,
  },
  data() {
    return {
      params: {
        isSimplify: 'Y',
      },
      formShow: false,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
      typeList: [],
    };
  },
  async mounted() {
    await this.getConfigList('need-goods-list');
    this.getOrderType();
  },

  methods: {
    /**
     * 获取订单类型
     * */
    async getOrderType() {
      const { result } = await this.$http.post('/dms/dms/order/orderTypeList', {});
      this.typeList = result;
    },
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'branchList') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/mdmOrgController/findOrgList';
        rowData.optionsKey = {
          label: 'orgName',
          value: 'orgCode',
        };
        rowData.multiple = true;
        // rowData.isSearch = true;
        rowData.paramsName = 'orgName';
        rowData.paramsFixed = {
          orgType: 'area',
        };
      }
      return rowData;
    },

    clickVisible({ row, val: { code } }) {
      const {
        approveStatus, processCode, hasAccounted, isHaveCar, processInstanceId,
      } = row;
      // 审批通过 approved,审批中 approving,活动关闭 closed,流程追回 interrupt,驳回 rejected
      if (code === 'process_log' && !processCode) {
        return false;
      }
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === '2' || approveStatus === '1')) {
        return false;
      }
      if (code === 'confirm_the_inventory' && (approveStatus !== '2' || hasAccounted === 'Y')) {
        return false;
      }
      if (code === 'isCart' && (isHaveCar === 'Y' || (approveStatus !== '1' && approveStatus !== '2'))) {
        return false;
      }
      if (code === 'work-flow-deal' && !processInstanceId) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        orderCode: '', // 数据code
        formShow: true,
      };
      if (val.code === 'add') {
        formPageArg.drawerTitle = '新增';
        this.createFormPage(formPageArg);
      }

      if (val.code === 'view') {
        formPageArg.drawerTitle = '查看';
        formPageArg.orderCode = row.orderCode;
        this.createFormPage(formPageArg);
      }
      if (val.code === 'edit') {
        let flag = false;
        for (const item of this.typeList) {
          if (item.orderTypeCode === row.orderType) {
            flag = true;
          }
        }
        if (!flag) {
          this.$message.error('您无此订单类型编辑权限');
        } else {
          formPageArg.drawerTitle = '编辑';
          formPageArg.orderCode = row.orderCode;
          this.createFormPage(formPageArg);
        }
      }
      if (val.code === 'work-flow-deal') {
        this.createAudit(formPageArg, row);
      }
    },

    createAudit(arg, row) {
      const Component = Vue.extend(audit);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
            row: { ...row },
          };
        },
      });
      this.vm1 = vm;
      document.body.appendChild(vm.$el);
    },
    createFormPage(arg) {
      const that = this;
      const Component = Vue.extend(FormPage);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('need-goods-list');
          },
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
